var isMobile = new Object();
isMobile.Android = function () {
	return navigator.userAgent.match(/Android/i);
};

isMobile.BlackBerry = function () {
	return navigator.userAgent.match(/BlackBerry/i);
};
isMobile.iOS = function () {
	return navigator.userAgent.match(/iPhone|iPad|iPod/i);
};

isMobile.iPad = function () {
	return navigator.userAgent.match(/iPad/i);
};
isMobile.Opera = function () {
	return navigator.userAgent.match(/Opera Mini/i);
};
isMobile.Windows = function () {
	return navigator.userAgent.match(/IEMobile/i);
};

isMobile.Firefox = function () {
	return navigator.userAgent.match(/Firefox/gi);
};
isMobile.InternetExplorer = function () {
	return navigator.userAgent.match(/MSIE/gi);
};
isMobile.Opera = function () {
	return navigator.userAgent.match(/Opera/gi);
};
isMobile.Safari = function () {
	return navigator.userAgent.match(/Safari/gi);
};
isMobile.any = function () {
	return (
		isMobile.Android() ||
		isMobile.BlackBerry() ||
		isMobile.iOS() ||
		isMobile.Opera() ||
		isMobile.Windows()
	);
};
if (isMobile.any() && isMobile.iPad() == null) {
	//return;
}

var ua = window.navigator.userAgent;
var is_ie = /MSIE|Trident/.test(ua);

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

var winWT = $(window).innerWidth();
var winHT = $(window).innerHeight();

var disableScrolling = function () {
	var x = window.scrollX;
	var y = window.scrollY;
	window.onscroll = function () { window.scrollTo(x, y); };
}
var enableScrolling = function () {
	window.onscroll = function () { };
}
window.onbeforeunload = function () {
	//window.scrollTo(0, 0);
}

$(window).on('load', function () {
	//window.scrollTo(0, 0);
});


$(document).ready(function () {
	//console.log('ganpati bappa morya!!!');
	$(".slimmenu").slimmenu({
		resizeWidth: '1200',
		collapserTitle: '',
		animSpeed: '500',
		easingEffect: null,
		indentChildren: false,
		childrenIndenter: '&nbsp;'
	});

	var mobileNavSetup = function () {
		winWT = $(window).innerWidth();
		winHT = $(window).innerHeight();
		var navWT = $("nav").innerWidth();
		$("#nav-icon1").removeClass("open");
		//gsap.set("nav", { display: "block", });
		gsap.to(".nav_overlay", 0.5, { opacity: 0, ease: Power2.easeInOut, });
		gsap.to("nav", 0.5, { right: -navWT + "px", ease: Power2.easeInOut, });
		gsap.set(".nav_overlay", { display: "none", });
		//gsap.set("ul.slimmenu>li", { opacity: 1, right: 0, });

		if (winWT <= 1024) {
			//$("nav").css({"height":winHT+"px",});
			var tempSocialHld = $('#social_con');
			tempSocialHld.remove();
			$(tempSocialHld).insertBefore('.mob_glen_logo');
		}
	};
	mobileNavSetup();
	$(".nav_overlay").click(function () {
		$("#nav-icon1").trigger("click");
	})
	$("#nav-icon1").click(function () {
		//$(this).toggleClass('open');
		if ($(this).hasClass("open")) {
			$(this).removeClass("open");
			mobileNavSetup();
		} else {
			$(this).addClass("open");
			gsap.set(".nav_overlay", { display: "block", });
			gsap.to(".nav_overlay", 0.5, { opacity: 0.8, ease: Power2.easeInOut, });
			gsap.to("nav", 0.5, { right: "0px", ease: Power2.easeInOut, });
			TweenMax.staggerFrom("ul.slimmenu>li", 0.8, { opacity: 0, x: -30, ease: Power2.easeInOut }, 0.1);
		}
	});

	function setMenuReachUsMob() {
		var tempReachUs = $('.reach_us').remove();
		$(tempReachUs).insertAfter('nav ul.slimmenu');
	}
	function setMenuReachUsDesk() {
		var tempReachUs = $('.reach_us').remove();
		$(tempReachUs).insertAfter('.header .nav_hld');
	}
	if (winWT <= 1200) {
		setMenuReachUsMob();
	} else {
		setMenuReachUsDesk();
	}

	$(window).resize(function () {
		winWT = $(window).innerWidth();
		if (winWT <= 1200) {
			setMenuReachUsMob();
		} else {
			setMenuReachUsDesk();
		}
	});

	function stickyNav() {
		if ($(window).scrollTop() >= 100) {
			$('header').addClass('sticky');
		}
		else {
			$('header').removeClass('sticky');
		}
	}
	$(window).on('scroll', function () {
		stickyNav();
	});

	$('.hd_user_icon').on('click', function () {
		if ($('.left_nav .user_list').css('display') == 'none') {
			$('.left_nav .user_list').slideDown();
		} else {
			$('.left_nav .user_list').slideUp();
		}

	});

	$('.left_nav .search_icon').on('click', function () {
		if ($(this).hasClass('active_serch')) {
			$(this).removeClass('active_serch');
			$('#search_popup').fadeOut(300);
			$('body').css({ overflow: 'auto' })
		} else {
			$(this).addClass('active_serch');
			$('#search_popup').fadeIn(300);
			$('body').css({ overflow: 'hidden' })
		}
	});
	$('#search_popup .search_overlay').on('click', function () {
		$('.left_nav .search_icon').removeClass('active_serch');
		$('#search_popup').fadeOut(300);
		$('body').css({ overflow: 'auto' })
	});

	if ($('.client_list_hld').length == 0) {
		return;
	} else {
		$('.client_list_hld').slick({
			centerMode: true,
			centerPadding: '50px',
			slidesToShow: 6,
			slidesToScroll: 1,
			dots: false,
			arrows: false,
			autoplay: true,
			infinite: true,
			speed: 400,
			responsive: [
				{
					breakpoint: 1500,
					settings: {
						slidesToShow: 5,
					}
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 4,
					}
				},
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 500,
					settings: {
						slidesToShow: 1,
						centerPadding: '30px',
					}
				}
			]
		});
	}

	if ($('.experties_blurb_hld').length == 0) {
		return;
	} else {
		$('.experties_blurb_hld').slick({
			slidesToShow: 7,
			slidesToScroll: 1,
			dots: false,
			arrows: false,
			autoplay: true,
			infinite: true,
			speed: 400,
			responsive: [
				{
					breakpoint: 10000,
					settings: "unslick"
				},
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 5,
						autoplay: true,
						infinite: true,
					}
				},
				{
					breakpoint: 1023,
					settings: {
						slidesToShow: 4
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 375,
					settings: {
						slidesToShow: 1
					}
				}
			]
		});
	};
});

$(document).ready(function () {
	// ----- Customer Reviews ----- //
	$('#cusReviewSlider').slick({
		dots: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: true,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]
	});
	// Video Popup
	$('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: true,
	});
	// ----- Scroll till Accordian Panel ----- //
	$('.fs-accordion').on('shown.bs.collapse', function () {		
		var tabHeight = $(this).find('.accordion-header').height() + $('.fs-header').height();
		//alert(tabHeight);
		var panel = $(this).find('.collapse.show');
		$('html, body').animate({
			scrollTop: panel.offset().top - tabHeight - 10
		}, 800);
	});
	// ----- Scroll till Accordian Panel ----- //
	$('.accordion').on('shown.bs.collapse', function () {
		var tabHeight = $(this).find('.accordion-header').height() + $('.fs-header').height();
		var panel = $(this).find('.collapse.show');
		$('html, body').animate({
			scrollTop: panel.offset().top - tabHeight - 10
		}, 800);
	});
	// ----- Get in Touch ----- //
	$('.getInTouchCTA').magnificPopup({
		type: 'ajax',
		closeOnContentClick: true,
		modal: true,
		closeOnBgClick: true
	});
});
