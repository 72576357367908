$(document).ready(function () {
	var winWT = $(window).innerWidth();

	$('#banner').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		fade: true,
		arrows: false,
		autoplay: true,
		infinite: false,
		speed: 400,
		responsive: []
	});

	function serviceDesktopSetup(){
		$('.services .services_blurb').each(function(){
			$(this).hover(function(){
				$('.services .services_blurb').removeClass('active');
				$(this).addClass('active');
			})
		});
	}
	
	function serviceMobSetup(){
		$('.services .services_blurb').removeClass('active');
		$('.services .services_hld').slick({
			slidesToShow: 2,
			slidesToScroll: 1,
			dots: true,
			arrows: false,
			autoplay: false,
			infinite: false,
			speed: 400,
			responsive: [
				{
					breakpoint: 10000,
					settings: "unslick"
				},
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 2,
					}
				},
			]
		});
	}

	/* $('.services .services_ratings').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		autoplay: true,
		infinite: true,
		speed: 400,
		responsive: [
			{
				breakpoint: 1110,
				settings: {
					slidesToShow: 3,
					autoplay: true,
					infinite: true,
				}
			}
		]
	});*/
	 
	if(winWT >= 1100){
		serviceDesktopSetup();
	}else{
		$('.services .services_blurb').removeClass('active');
		//serviceMobSetup();
	}

	$(window).resize(function(){
		winWT = $(window).innerWidth();
		if(winWT >= 1100){
			serviceDesktopSetup();
		}else{
			$('.services .services_blurb').removeClass('active');
			//serviceMobSetup();
		}
	});

	

	

});